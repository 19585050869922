import React, { useState } from 'react';
import classnames from 'classnames';

import { NavBar, Tab, Snackbar } from '../../Components';
import './Plan.scss';
import './infinity-fitness-charleston.webflow.css';

interface TabData {
  lineTextList: string[];
  cost: number;
  monthly: boolean;
  header: string;
  subtext: string;
  id: string;
}

function Plan() {
  const [pricingType, setPricingType] = useState('individual');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const individualMonthly = {
    lineTextList: [
      'Easy auto billing setup',
      '24/7 access to facility',
      'Clean environment',
      'Sauna access',
    ],
    cost: 39,
    monthly: true,
    header: 'Individual Monthly',
    subtext:
      'For those looking for a great individual membership to hit their goals',
    id: 1,
  };

  const individualMonthlyDiscount = {
    lineTextList: [
      '10% discount',
      'Easy auto billing setup',
      '24/7 access to facility',
      'Clean environment',
      'Sauna',
    ],
    cost: 35,
    monthly: true,
    header: 'Individual Monthly Discount',
    subtext: 'For our students, military, and seniors',
    id: 2,
  };

  const individualYearly = {
    lineTextList: [
      'Free month for yearly sign-up',
      'Easy auto billing setup',
      '24/7 access to facility',
      'Clean environment',
      'Sauna access',
    ],
    cost: 429,
    monthly: false,
    header: 'Individual Yearly',
    subtext: 'For those looking for a great yearly individual membership',
    id: 3,
  };

  const individualYearlyDiscount = {
    lineTextList: [
      'Free month for yearly sign-up',
      'Easy auto billing setup',
      '24/7 access to facility',
      'Clean environment',
      'Sauna access',
    ],
    cost: 385,
    monthly: false,
    header: 'Individual Yearly Discount',
    subtext: 'For our students, military, and seniors',
    id: 4,
  };

  const familyMonthly = {
    lineTextList: [
      'Includes 2 family members ($15 for each additional member)',
      'Monthly no-contract membership',
      'Easy auto billing setup',
      '24/7 facility access',
      'Clean facility',
      'Sauna access',
    ],
    cost: 69,
    monthly: true,
    header: 'Family Monthly',
    subtext: 'Monthly membership for families',
    id: 5,
  };

  const familyMonthlyDiscount = {
    lineTextList: [
      '10% discount',
      'Includes 2 family members ($15 for each additional member)',
      'Monthly no-contract membership',
      'Easy auto billing setup',
      '24/7 facility access',
      'Clean facility',
      'Sauna access',
    ],
    cost: 62,
    monthly: true,
    header: 'Family Monthly Discount',
    subtext: 'Monthly membership for students, military, and seniors',
    id: 6,
  };

  const familyYearly = {
    lineTextList: [
      'Includes 2 family members ($15 for each additional member)',
      'Monthly no-contract membership',
      'Easy auto billing setup',
      '24/7 facility access',
      'Clean facility',
      'Sauna access',
    ],
    cost: 795,
    monthly: false,
    header: 'Family Yearly',
    subtext: 'Yearly membership for families',
    id: 7,
  };

  return (
    <div>
      {loading && (
        <div className="content">
          <div className="loading">
            <p>loading</p>
            <span></span>
          </div>
        </div>
      )}
      <NavBar />
      <div className=" wf-section">
        <div className="brix---container-default w-container">
          <div className="brix---mg-bottom-48px">
            <div
              data-w-id="4c85f601-6cbf-3e32-4911-39bc6683f542"
              // style="-webkit-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
              className="brix---inner-container-700px-center"
            >
              <div className="brix---text-center">
                <div className="brix---subtitle">Pricing</div>
                <div className="brix---color-neutral-800">
                  <h1 className="brix---heading-h1-size">
                    Affordable pricing plans
                  </h1>
                </div>
                <div className="brix---color-neutral-600">
                  <p className="brix---paragraph-default">
                    We offer both individual and family pricing plans. Both of
                    our plans offer a 10% discount to students, military, and
                    seniors.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-w-id="4c85f601-6cbf-3e32-4911-39bc6683f54c"
            // style="-webkit-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
            data-current="Monthly Plan"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="brix---tabs-wrapper w-tabs"
          >
            <div className="brix---tabs-menu w-tab-menu">
              <a
                onClick={() => setPricingType('individual')}
                className={classnames({
                  'brix---badge-secondary---tabs': true,
                  'w-inline-block': true,
                  'w-tab-link': true,
                  'w--current': pricingType === 'individual',
                })}
              >
                <div>Individual Pricing Plans</div>
              </a>
              <a
                onClick={() => setPricingType('family')}
                className={classnames({
                  'brix---badge-secondary---tabs': true,
                  'w-inline-block': true,
                  'w-tab-link': true,
                  'w--current': pricingType === 'family',
                })}
              >
                <div>Family Pricing Plans</div>
              </a>
            </div>
            <div className="brix---tabs-content w-tab-content">
              <div data-w-tab="Annual Plan" className="w-tab-pane">
                {pricingType === 'individual' && (
                  <div className="w-layout-grid brix---4-columns-1-col-tablet">
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={individualMonthly}
                    />
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={individualMonthlyDiscount}
                    />
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={individualYearly}
                    />
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={individualYearlyDiscount}
                    />
                  </div>
                )}
                {pricingType === 'family' && (
                  <div className="w-layout-grid brix---3-columns-1-col-tablet">
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={familyMonthly}
                    />
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={familyYearly}
                    />
                    <Tab
                      setLoading={setLoading}
                      setError={setError}
                      tabData={familyMonthlyDiscount}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && <Snackbar />}
    </div>
  );
}

export default Plan;
