import React from 'react';

import './Snackbar.scss';

const Snackbar = () => {
  return (
    <div id="snackbar" className="show">
      There was an Error. Please reload and try again
    </div>
  );
};

export default Snackbar;
