import React from 'react';

import './Success.css';

function Success() {
  return (
    <div>
      <div className="card">
        <div
          style={{
            borderRadius: '200px',
            height: '200px',
            width: '200px',
            background: '#F8FAF5',
            margin: '0 auto',
          }}
        >
          <i className="checkmark">✓</i>
        </div>
        <h1>Success</h1>
        <p className="success-text">
          Thank you for your recent payment! Please come by during staff hours
          to complete a bit more paperwork and receive your door entry card.
        </p>
        <p className="success-text">We have staff available</p>
        <p className="success-text">Monday - Friday: 7-9 AM</p>
        <p className="success-text">Monday - Thursday: 4-6 PM</p>
        <p className="success-text">Saturday: 11-1</p>
        <p className="success-text">
          If those times do not work for you, please call us at
          <a href="tel:217-348-8883" aria-hidden="true">
            {`         217-348-8883  `}
          </a>
          and we can set up a time to meet at the gym.
        </p>
        <p className="success-text">Thank you, Matt</p>
        <a
          href="https://www.google.com/maps/place/Infinity+Fitness/@39.4872081,-88.1633872,17z/data=!3m1!4b1!4m5!3m4!1s0x88731d479141d3a1:0x453c9ebdf4c2e7e5!8m2!3d39.4872081!4d-88.1633872?hl=en-US"
          className="brix---badge-secondary---tabs w-inline-block w-tab-link w--current success-text"
        >
          <span className="elementor-button-content-wrapper">
            <span className="elementor-button-text">
              Click here for directions
            </span>
          </span>
        </a>
      </div>
    </div>
  );
}

export default Success;
