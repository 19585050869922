import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './NavBar.scss';

import '../../Pages/Main/animate.css';
import '../../Pages/Main/bootstrap.css';
import '../../Pages/Main/icomoon.css';
import '../../Pages/Main/style.css';
import '../../Pages/Main/superfish.css';

const NavBar = () => {
  const scrollById = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/ourstory') {
      const idToScroll = location.hash.slice(1);
      scrollById(idToScroll);
    }
  }, [location]);

  return (
    <div className="navbar navbar-fixed-top navbar-default mu-navbar">
      <div className="container">
        {/* <!-- Brand and toggle get grouped for better mobile display --> */}
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          {/* <!-- Logo --> */}
          <a className="navbar-brand" href="/#mu-hero">
            Infinity Fitness
          </a>
        </div>

        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav mu-menu navbar-right">
            <li>
              <a href="/#mu-hero">Home</a>
            </li>
            <li>
              <a href="/#mu-about">About Us</a>
            </li>
            <li>
              <a href="/#mu-schedule">Trainers</a>
            </li>
            <li>
              <a href="/#mu-pricing">Price</a>
            </li>
            <li>
              <a href="/register">Register</a>
            </li>
            <li>
              <a href="/#mu-contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- End menu --> */}
    </div>
  );
};

export default NavBar;
