import React from 'react';
import { Plan, Success, Main } from './Pages';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/register" element={<Plan />} />
          <Route path="/success" element={<Success />} />
          <Route path="/*" element={<div> 404 no page found </div>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
