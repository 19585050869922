import React from 'react';

import { NavBar } from '../../Components';

import './Main.css';
import './animate.css';
import './bootstrap.css';
import './icomoon.css';
import './style.css';
import './superfish.css';

const Main = () => {
  return (
    <div>
      {/* <!-- Start Header --> */}
      <header id="mu-hero">
        {/* <!-- Start menu  --> */}
        <NavBar />

        <div className="mu-hero-overlay">
          <div className="container">
            <div className="mu-hero-area">
              {/* <!-- Start hero featured area --> */}
              <div className="mu-hero-featured-area">
                <div className="mu-hero-featured-content">
                  <h1> WE ARE INFINITY FITNESS </h1>
                  <h2>
                    <a href="tel:217-348-8883" aria-hidden="true">
                      217-348-8883
                    </a>
                  </h2>
                  <p className="mu-event-date-line">
                    1100 18th St, Charleston, IL 61920, USA
                  </p>
                  {/* <!-- Got rid of the counter  --> */}

                  <div className="mu-event-counter-area">
                    {/* <!-- <div id="mu-event-counter"> */}
                  </div>
                </div>

                {/* <!-- Got rid of the counter  --> */}
              </div>
            </div>
            {/* <!-- End hero featured area --> */}
          </div>
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- Start main content --> */}
      <main role="main">
        {/* <!-- Start About --> */}
        <section id="mu-about">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mu-about-area">
                  {/* <!-- Start Feature Content --> */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mu-about-left">
                        {/* <img
                          className=""
                          src="./images/Tred.jpg"
                          alt="Men Speaker"
                        /> */}
                        <div className="main__container">
                          <iframe
                            src="https://www.youtube.com/embed/GJAeSxw68CM"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            className="main__video"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mu-about-right">
                        <h2>About Us</h2>
                        <p>
                          At Infinity Fitness, we’re dedicated to providing a
                          motivating environment, outfitted with the equipment
                          to get your workout going. Offering 24/7 access and a
                          serious selection of equipment, we make reaching your
                          fitness goals only a matter of when.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Feature Content --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About --> */}

        <div
          className="elementor-element elementor-element-31185d08 elementor-button-info elementor-align-center elementor-widget elementor-widget-button animated bounceIn"
          data-id="31185d08"
          data-element_type="widget"
          data-settings='{"_animation":"bounceIn","_animation_delay":2500}'
          data-widget_type="button.default"
        >
          <div className="elementor-widget-container">
            <div className="elementor-button-wrapper"></div>
          </div>
        </div>

        {/* <!-- Start Pricing  --> */}
        <section id="mu-pricin">
          <div className="container">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
        </section>
        {/* <!-- End Pricing --> */}

        <div
          className="fh5co-parallax"
          style={{ backgroundImage: 'home-image-3.jpg' }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12 col-xs-offset-0 text-center fh5co-table">
                <div className="fh5co-intro fh5co-table-cell animate-box">
                  <h1 className="text-center">Commit To Be Fit</h1>
                  <a
                    className="pricing__action btn btn-default"
                    href="/register"
                  >
                    Join Today
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end: fh5co-parallax --> */}
        <div id="fh5co-programs-section">
          <div className="container">
            <div className="">
              <div className="col-md-8 col-md-offset-2">
                <div className="heading-section text-center animate-box">
                  <h2>BENEFITS</h2>
                </div>
              </div>
            </div>

            <div className=" text-center">
              <div className="col-md-4 col-sm-6">
                <div className="program animate-box">
                  <img src="./images/wifi.png" alt="" />
                  <h3>Free Wifi</h3>
                  <p></p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="program animate-box">
                  <img src="./images/24-Hours-PNG-HD.png" alt="" />
                  <h3>24 Hour Key Card Access</h3>
                  <p></p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="program animate-box">
                  <img src="./images/Sauna.png" alt="" />
                  <h3>Sauna</h3>
                  <p></p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="program animate-box">
                  <img src="./images/silversneakers.jpg" alt="" />
                  <h3>Silver Sneakers</h3>
                  <p></p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="program animate-box">
                  <img src="./images/Silver.png" alt="" />
                  <h3>Silver & Fit</h3>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ========================= */}
        {/* OVERVIEW SECTION    */}
        {/* ============================== --> */}

        {/* <!-- Traners  --> */}
        <div id="fh5co-team-section" className="fh5co-lightgray-section">
          <div className="container">
            <div id="mu-schedule" className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="heading-section text-center animate-box">
                  <h2>Meet Our Trainers</h2>
                  <p className="trainer-bio"></p>
                </div>
              </div>
            </div>
            <div className="aaaaa traners-moble  text-center">
              <div className="asdasdas ">
                <div
                  className="team-section-grid animate-box"
                  style={{ backgroundImage: 'images/trainer-1.jpg' }}
                >
                  <div id="lisa" className="overlay-section">
                    <div className="desc overMe">
                      <div className="trainer-bio-div">
                        <div className="trainer-text">
                          <h3>Lisa Dallas</h3>
                          <span>Fitness Instructor</span>
                        </div>
                        <p className="trainer-bio">
                          Professional fitness instructor for over 30 years. MS
                          degree from EIU with an emphasis and exercise science
                          and study using lightweights with cardio exercise.
                          Personal trainer and certified Pound Fit instructor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="asdasdas ">
                <div
                  className="team-section-grid animate-box"
                  style={{ backgroundImage: 'images/trainer-1.jpg' }}
                >
                  <div id="paula" className="overlay-section">
                    <div className="desc overMe">
                      <div className="trainer-bio-div">
                        <div className="trainer-text">
                          <h3>Paula Pogue</h3>
                          <span>Personal Fitness Trainer</span>
                        </div>
                        <p className="trainer-bio">
                          certified through Athletics and Fitness Association of
                          America.In addition to personal fitness training,
                          Paula teaches three Pilates classes that are a mix of
                          balance, flexibility, toning and strength training.
                          The class is a full hour with 30 minutes standing body
                          weight work and 30 minutes of core and lower body
                          work. It is a mix of Pilates, Yoga, and exercises that
                          tone and help shape the body.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="asdasdas ">
                <div
                  className="team-section-grid animate-box"
                  style={{ backgroundImage: 'images/trainer-1.jpg' }}
                >
                  <div id="guy" className="overlay-section">
                    <div className="desc overMe">
                      <div className="trainer-bio-div">
                        <div className="trainer-text">
                          <h3>Marcus McKibben</h3>
                          <span>Personal Trainer</span>
                        </div>

                        <p className="trainer-bio">
                          Expertise training in all fields from running,
                          lifting, weight loss, bodybuilding, powerlifting,
                          cycling, soccer, golf, baseball, football, basketball,
                          track & field, wrestling, & rehabilitation.
                          Personalized workouts and weekly health tips &
                          articles for each individual that will help reach your
                          goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="asdasdas ">
                <div
                  className="team-section-grid animate-box"
                  style={{ backgroundImage: 'images/trainer-2.jpg' }}
                >
                  <div id="amanda" className="overlay-section">
                    <div className="desc overMe">
                      <div className="trainer-bio-div">
                        <div className="trainer-text">
                          <h3>AMANDA WHITE</h3>
                          <span>Certified Personal Trainer</span>
                        </div>
                        <p className="trainer-bio">
                          I've got 6 years of experience with the ups and downs
                          of making a healthy lifestyle my priority. It has
                          become one of my top passions and I want to help
                          others reach their physical and mental potential.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- END fh5co-page --> */}
        </div>

        {/* <!-- ========================= */}
        {/* PRICE SECTION    */}
        {/* ============================== --> */}
        <section id="mu-pricing">
          <section id="price" className="parallax-section">
            <div className="container">
              <div className="">
                <div>
                  <h2>Register Today</h2>
                </div>

                <div>
                  <div className="pricing__item">
                    <h3 className="pricing__title">
                      MAKE IT A LIFESTYLE, NOT A DUTY
                    </h3>

                    <li className="pricing__action">
                      <a className="register-button" href="/register">
                        Check Out Our Prices
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* <!-- ========================= */}
        {/* // TESTIMONIAL SECTION    */}
        {/* ============================== --> */}
        <section id="testimonial" className="parallax-section">
          <div className="container">
            <div className="row">
              {/* <!-- Testimonial Owl Carousel section */}
              {/* ================================================== --> */}
              <div id="owl-testimonial" className="owl-carousel">
                <div
                  className="item col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <i className="fa fa-quote-left"></i>
                  <h3>
                    Lorem ipsum dolor sit amet, maecenas eget vestibulum justo
                    imperdiet, wisi risus purus augue vulputate.
                  </h3>
                  <h4>Sandar ( Fashion Designer )</h4>
                </div>

                <div
                  className="item col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <i className="fa fa-quote-left"></i>
                  <h3>
                    Yes! Lorem ipsum dolor sit amet, maecenas eget vestibulum
                    justo imperdiet, wisi risus purus augue.
                  </h3>
                  <h4>James Job ( Social Assistant )</h4>
                </div>

                <div
                  className="item col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <i className="fa fa-quote-left"></i>
                  <h3>
                    Sed dapibus rutrum lobortis. Sed nec interdum nunc, quis
                    sodales ante. Maecenas volutpat congue.
                  </h3>
                  <h4>Mark Otto ( New Cyclist )</h4>
                </div>

                <div
                  className="item col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <i className="fa fa-quote-left"></i>
                  <h3>
                    Lorem ipsum dolor sit amet, maecenas eget vestibulum justo
                    imperdiet, wisi risus purus.
                  </h3>
                  <h4>David Moore ( Pro Boxer )</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="mu-contact">
          <div className="loll">
            <div className="lollHey">
              <h1>Staff Hours</h1>
              <h3 style={{ textAlign: 'center' }}>Charleston</h3>
              <h3 style={{ textAlign: 'center' }}>
                1100 18th St, Charleston, IL 61920
              </h3>
              <h3 style={{ textAlign: 'center' }}>Monday-Friday</h3>
              <h3 style={{ textAlign: 'center' }}>7AM–9AM</h3>
              <h3 style={{ textAlign: 'center' }}>Monday-Thursday</h3>
              <h3 style={{ textAlign: 'center' }}>4PM–6PM</h3>
              <h3 style={{ textAlign: 'center' }}>Saturday 11AM–1PM</h3>
              <h1 className="ttim">24 HOUR ACCESS</h1>
            </div>
          </div>

          <div className="spacee">
            <a
              href="https://www.google.com/maps/place/Infinity+Fitness/@39.4872081,-88.1633872,17z/data=!3m1!4b1!4m5!3m4!1s0x88731d479141d3a1:0x453c9ebdf4c2e7e5!8m2!3d39.4872081!4d-88.1633872?hl=en-US"
              className="brix---badge-secondary---tabs w-inline-block w-tab-link w--current"
            >
              <span className="elementor-button-content-wrapper">
                <span className="elementor-button-text">
                  Click here for directions
                </span>
              </span>
            </a>
            <h2 className="new-dev">
              <a href="tel:217-348-8883" aria-hidden="true">
                217-348-8883
              </a>
            </h2>
          </div>

          <section id="mu-venue">
            <div className="mu-venue-area">
              <div className="row">
                <div className="col-md-6">
                  <div className="mu-venue-map">
                    <iframe
                      src="https://maps.google.com/maps?q=Infinity%20Fitness%2C%2018th%20Street%2C%20Charleston%2C%20IL&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                      width="100%"
                      height="450"
                      style={{ border: 0 }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>

      {/* <!-- End main content -->	 */}

      {/* <!-- Start footer --> */}
      <footer id="mu-footer" role="contentinfo">
        <div className="container">
          <div className="mu-footer-area">
            <div className="mu-footer-top">
              <div className="mu-social-media">
                <a
                  href="tel:217-348-8883"
                  className="fa fa-phone"
                  aria-hidden="true"
                ></a>
                <a href="https://www.facebook.com/InfinityFitnessIL/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/infinityfitness84/">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="mu-footer-bottom">
              <p className="mu-copy-right">
                &copy; Copyright{' '}
                <a rel="nofollow" href="http://brainydeveloper.com">
                  Infinity Fitness
                </a>
                . All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Main;
