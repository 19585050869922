import React, { useState } from 'react';
import TabLineItem from '../TabLineItem';

import './Tab.scss';

interface TabProps {
  tabData: TabData;
  setLoading: any;
  setError: any;
}

interface TabData {
  lineTextList: string[];
  cost: number;
  monthly: boolean;
  header: string;
  subtext: string;
  id: number;
}

const Tab = ({ tabData, setLoading, setError }: TabProps) => {
  const { lineTextList, cost, monthly, header, subtext, id } = tabData;

  const handleCheckout = () => {
    setLoading(true);
    fetch(
      'https://infinity-fitness-main-prod.onrender.com/create-checkout-session',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: [
            { id, quantity: 1, name: header },
            { id: 0, quantity: 1, name: 'startup fee' },
          ],
        }),
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        setLoading(false);
        window.location = url;
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        console.log('HI THERE ');
        console.log(e);
        console.error(e.error);
      });
  };

  return (
    <div className="brix---card">
      <div className="brix---pricing-content-v2">
        <div className="brix---mg-bottom-32px">
          <div className="brix---color-neutral-800">
            <h3 className="brix---heading-h3-size">{header}</h3>
          </div>
          <div className="brix---flex-horizontal-start">
            <div className="brix---mg-right-8px">
              <div className="brix---color-neutral-800">
                <div className="brix---heading-h1-size">{`$${cost}`}</div>
              </div>
            </div>
            <div className="brix---color-neutral-600">
              <div className="brix---text-300-medium">{`/${
                monthly ? 'month' : 'year'
              }`}</div>
            </div>
          </div>
          <div className="brix---color-neutral-600">
            <p className="brix---paragraph-default">{subtext}</p>
          </div>
        </div>
        <div className="brix---divider-40px"></div>
        <TabLineItem lineTextList={lineTextList} />

        <a
          onClick={handleCheckout}
          href="#"
          className="brix---btn-primary-full-width w-button"
        >
          CHECKOUT
        </a>
      </div>
    </div>
  );
};

export default Tab;
