import React from 'react';

import './TabLineItem.css';

interface TabLineItemProps {
  lineTextList: string[];
}

const TabLineItem = ({ lineTextList }: TabLineItemProps) => {
  const displayTextLines = lineTextList.map((text, i) => (
    <div key={`${text}: ${i}`} className="brix---icon-list-item-wrapper">
      <img
        src="images/check-icon-brix-templates.svg"
        alt="Check - Elements Webflow Library - BRIX Templates"
        className="brix---icon-list"
      ></img>
      <div className="brix---color-neutral-800">
        <div className="brix---text-200-list-bold">{text}</div>
      </div>
    </div>
  ));

  return (
    <div className="brix---mg-bottom-40px">
      <div className="w-layout-grid brix---grid-1-column-gap-row-16px">
        {displayTextLines}
      </div>
    </div>
  );
};

export default TabLineItem;
